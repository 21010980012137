import React, { useState, useMemo } from "react";
import { Link, graphql } from "gatsby";
import { AnnouncementContainer } from "../components/AnnouncementContainer";
import { MoreButton } from "../components/MoreButton";

const LAST = 6;

export default function Announcements({ data }) {
  const announcements = data.allMarkdownRemark.edges;
  const [page, setPage] = useState(1);
  const List = useMemo(
    () => (
      <ul>
        {announcements.slice(0, LAST * page).map(({ node }) => (
          <li key={node.id} className="border-t-[5px] border-black lg:mb-12">
            <Link to={`/announcements/${node.frontmatter?.slug}`} className="block">
              <time className="text-sm lg:text-2xl block my-2.5">{node.frontmatter?.date}</time>
              <h2 className="font-bold my-5 text-primary-100 lg:text-2xl">
                {node.frontmatter?.title}
              </h2>
            </Link>
          </li>
        ))}
      </ul>
    ),
    [page, announcements]
  );
  const hasMore = page * LAST < announcements.length;
  const handleViewMore = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };
  const More = useMemo(
    () => hasMore && <MoreButton onClick={handleViewMore}>VIEW MORE</MoreButton>,
    [handleViewMore, hasMore]
  );

  return <AnnouncementContainer append={More}>{List}</AnnouncementContainer>;
}

export const query = graphql`
  query Announcements {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "announcement" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            date
          }
        }
      }
    }
  }
`;
